import { atom } from 'nanostores'
import { persistentMap, persistentAtom } from '@nanostores/persistent'

/**
 * This file is where we store client-side state management such as $chat, isChatLoading, isLoading, etc
 * This is used client-side only, and is not used for server-side rendering
 */

export type SettingsValue = {
  sidebar: 'show' | 'hide'
  theme: 'dark' | 'light' | 'auto'
}

export const settings = persistentMap<SettingsValue>('settings:', {
  sidebar: 'show',
  theme: 'auto',
})

// settings.setKey('sidebar', 'hide')
export const isOpen = atom(false)
export const isChatLoading = atom(false)
export const isLoading = atom(false)

export const areWeEmail = atom(false)
export const didWeAccept = atom(false)

export const showLoadingAIPage = atom(false)
export const showResumeImportPage = atom(false)

export const $chat = atom('')
export const $chats = atom([])
export const $sourceDocs = atom([])

export const chat2 = persistentAtom('chat', [], {
  encode: JSON.stringify,
  decode: JSON.parse,
})

export const activeBoard = atom('')
export const activeJob = atom('')

export const nextJob = persistentAtom('nextJob', '') //, { listen: false })
export const prevJob = persistentAtom('prevJob', '') //, { listen: false })

export type ResumeImportValue = {
  active: 'true' | 'false'
  time: ''
}

export const isResumeLoading = persistentAtom('isResumeLoading', '')
export const resumeImported = persistentAtom('resumeImported')

export const resumeImport = persistentMap<ResumeImportValue>('resumeImport:', {
  active: 'false',
  time: '',
})
/**
 * @typedef {Object} jobPosting
 * @property {string} id
 * @property {string} name
 */

/** @type {import('nanostores').MapStore<Record<string, jobPosting>>} */
export const jobPosting = persistentMap('jobPostings', {})

export const jobs2 = persistentAtom('jobs2', [], {
  encode: JSON.stringify,
  decode: JSON.parse,
})

/**
 * @typedef {Object} board
 * @property {string} id
 * @property {string} name
 */

/** @type {import('nanostores').MapStore<Record<string, board>>} */
export const boards = persistentMap('boards', {})

/**
 * @typedef {Object} feed
 * @property {string} id
 */

/** @type {import('nanostores').MapStore<Record<string, feed>>} */
export const jobFeeds = persistentMap('feeds', {})

export const $feedHistory = persistentAtom('feedHistory', [], {
  //listen: false,
  encode: JSON.stringify,
  decode: JSON.parse,
})

export const $onboarding = persistentMap('onboarding', {})

/*
resume: {},
skills: {},
aixscore: {},
profile: {},
lastUpdated = new Date()
*/

export const $resume = persistentAtom('resume', [], {
  encode: JSON.stringify,
  decode: JSON.parse,
})

export const $skills = persistentAtom('skills', [], {
  encode: JSON.stringify,
  decode: JSON.parse,
})

export const $score = persistentAtom('score', [], {
  encode: JSON.stringify,
  decode: JSON.parse,
})
